import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="footer text-center">
      <Container>
        <p className="mb-0">
          Copyright © 2019 Andrew Hawley. All Rights Reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
